<template>
  <Navbar />
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
  name: 'Header',
  components: { Navbar }
}

</script>
